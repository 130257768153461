/* eslint-disable react-hooks/rules-of-hooks */

import * as React from 'react'
import { useStyles } from 'react-treat'
import { navigate } from 'gatsby'
import {
  Box,
  useBoxStyles,
  usePseudoBoxStyles,
  BoxProps,
} from '@walltowall/calico'
import { Formik, Form as FormikForm, Field as FormikField } from 'formik'
import VisuallyHidden from '@reach/visually-hidden'
import ConditionalWrap from 'conditional-wrap'
import querystring from 'querystring'
import clsx from 'clsx'

import { useSiteSettings } from '../../hooks/useSiteSettings'
import { useNavigation } from '../../hooks/useNavigation'
import { useScrollDirection } from '../../hooks/useScrollDirection'
import { useModal } from '../../hooks/useModal'

import { BoundedBox } from '../../components/BoundedBox'
import { Icon } from '../../components/Icon'
import { Link, LinkProps } from '../../components/Link'
import { SVG } from '../../components/SVG'
import { Text } from '../../components/Text'
import { Inline } from '../../components/Inline'

import { ReactComponent as AssetLogoTextSVG } from '../../assets/logo-text.svg'
import { ReactComponent as AssetLogoIconSVG } from '../../assets/logo-icon.svg'

import * as styleRefs from './Desktop.treat'

type PrimaryNavListProps = {
  children?: React.ReactNode
}

const PrimaryNavList = ({ children }: PrimaryNavListProps) => (
  <Box
    component="ul"
    styles={{ display: 'flex', marginLeft: -4, marginRight: -4 }}
  >
    {children}
  </Box>
)

type PrimaryNavListItemProps = {
  href: LinkProps['href']
  name?: string
  children?: React.ReactNode
  defaultIsOpen?: boolean
}

PrimaryNavList.Item = ({ href, name, children }: PrimaryNavListItemProps) => {
  const styles = useStyles(styleRefs)
  const hasChildren = React.Children.count(children) > 0

  return (
    <Box
      component="li"
      className={styles.primaryNavListItemFocusCapture}
      styles={{
        position: 'relative',
        marginBottom: -7,
        paddingLeft: 4,
        paddingRight: 4,
        paddingBottom: 7,
      }}
    >
      <Link href={href}>
        <Text component="div" variant="sans-19-22">
          {name}
        </Text>
      </Link>

      <Box
        className={styles.primaryNavListItemIndicator}
        styles={{
          backgroundColor: 'white',
          bottom: 0,
          height: '3px',
          left: 0,
          marginLeft: 4,
          marginRight: 4,
          opacity: 0,
          position: 'absolute',
          right: 0,
          transitionDuration: 'normal',
          transitionProperty: 'opacity',
          transitionTimingFunction: 'easeOut',
        }}
      />
      {hasChildren && (
        <Box
          component="ul"
          className={clsx(styles.primaryNavListItemChildren, styles.ie11Hacks)}
          styles={{
            backgroundColor: 'white',
            color: 'brown20',
            left: 0,
            marginLeft: -2,
            opacity: 0,
            pointerEvents: 'none',
            position: 'absolute',
            top: '100%',
            transitionDuration: 'normal',
            transitionTimingFunction: 'easeOut',
            paddingTop: 2,
            paddingBottom: 2,
            width: '20rem',
            zIndex: -1,
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  )
}

type PrimaryListChildItemProps = {
  href: LinkProps['href']
  description?: string
  children?: string
}

PrimaryNavList.ChildItem = ({
  href,
  description,
  children,
}: PrimaryListChildItemProps) => {
  const styles = useStyles(styleRefs)

  const focusStyles = { backgroundColor: 'beige100' } as const
  const focusClassNames = clsx(
    styles.primaryNavListChildItemTransitionProperty,
    useBoxStyles({
      display: 'block',
      paddingTop: 3.5,
      paddingBottom: 3.5,
      paddingLeft: 6,
      paddingRight: 6,
      transitionProperty: 'color',
      transitionDuration: 'normal',
      transitionTimingFunction: 'easeOut',
    }),
    usePseudoBoxStyles(focusStyles, 'hover'),
    usePseudoBoxStyles(focusStyles, 'focus'),
  )

  return (
    <Box component="li">
      <Link href={href} className={focusClassNames}>
        <Text
          variant="sans-19-22"
          styles={{ color: 'fuschia30', marginBottom: 3 }}
        >
          {children}
        </Text>
        {description && <Text variant="sans-12">{description}</Text>}
      </Link>
    </Box>
  )
}

type SecondaryNavListProps = Omit<BoxProps, 'wrap'>

const SecondaryNavList = ({ children, ...props }: SecondaryNavListProps) => (
  <Inline variant="list" space={6} {...props}>
    {children}
  </Inline>
)

type SecondaryNavItemProps = {
  href?: LinkProps['href']
  children: React.ReactNode
}

SecondaryNavList.Item = ({ href, children }: SecondaryNavItemProps) => (
  <Text variant="sans-12" styles={{ fontWeight: 'medium' }}>
    <ConditionalWrap
      condition={Boolean(href)}
      wrap={(children) => <Link href={href!}>{children}</Link>}
    >
      <>{children}</>
    </ConditionalWrap>
  </Text>
)

const SearchForm = () => {
  const styles = useStyles(styleRefs)

  const onSearchSubmit = (values: { query: string }) => {
    const path = values.query
      ? `/search/?${querystring.encode({ query: values.query })}`
      : '/search/'
    navigate(path)
  }

  return (
    <Formik initialValues={{ query: '' }} onSubmit={onSearchSubmit}>
      <FormikForm>
        <Box styles={{ position: 'relative' }}>
          <VisuallyHidden>
            <label htmlFor="query">Search</label>
          </VisuallyHidden>
          <FormikField
            name="query"
            as={(props: object) => (
              <Box
                component="input"
                type="search"
                id="query"
                className={clsx(styles.placeholderColor, styles.inputWidth)}
                placeholder="Search&hellip;"
                styles={{
                  backgroundColor: 'maroon30',
                  borderRadius: '10em',
                  color: 'white',
                  display: 'block',
                  fontFamily: 'sans',
                  fontSize: '0.8rem',
                  lineHeight: 'solid',
                  paddingBottom: 1.5,
                  paddingLeft: 3,
                  paddingRight: 8,
                  paddingTop: 1.5,
                  transitionDuration: 'normal',
                  transitionTimingFunction: 'easeOut',
                }}
                {...props}
              />
            )}
          />
          <Box
            component="button"
            styles={{
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              paddingRight: 2,
              paddingLeft: 2,
            }}
          >
            <VisuallyHidden>Submit search</VisuallyHidden>
            <Icon
              name="search"
              className={styles.iconTranslate}
              styles={{ color: 'white', width: '0.75rem' }}
            />
          </Box>
        </Box>
      </FormikForm>
    </Formik>
  )
}

type DesktopProps = {
  withSignInLink?: boolean
} & BoxProps

export const Desktop = ({ withSignInLink = true, ...props }: DesktopProps) => {
  const styles = useStyles(styleRefs)
  const { openModal } = useModal()

  const { isScrollingDown } = useScrollDirection(200)

  const siteSettings = useSiteSettings()
  const navigation = useNavigation()

  const logoIconStyles = clsx(
    useBoxStyles({
      maxWidth: isScrollingDown ? '5rem' : '6.75rem',
      width: 'full',
      transitionDuration: 'normal',
      transitionTimingFunction: 'easeInOut',
      position: 'absolute',
      bottom: 0,
      left: 0,
      zIndex: 4, // Needed for IE 11 compat.
    }),
    styles.transformMaxWidthTransform,
    isScrollingDown ? styles.translateLogoIconMore : styles.translateLogoIcon,
  )

  const logoTextStyles = clsx(
    useBoxStyles({
      maxWidth: isScrollingDown ? '16rem' : '19rem',
      width: 'full',
      marginLeft: isScrollingDown ? 22 : 30,
      transitionDuration: 'normal',
      transitionTimingFunction: 'easeInOut',
    }),
    styles.transformMaxWidthMarginLeft,
  )

  return (
    <BoundedBox
      innerMaxWidth="large"
      className={clsx(
        styles.gradientBackground,
        styles.shadow,
        isScrollingDown && styles.translateBar,
      )}
      {...props}
      styles={{
        color: 'white',
        backgroundPositionX: 'left',
        backgroundPositionY: 'bottom',
        backgroundSize: 'cover',
        paddingTop: 9,
        paddingBottom: 7,
        transitionDuration: 'normal',
        transitionProperty: 'transform',
        transitionTimingFunction: 'easeInOut',
        ...props.styles,
      }}
    >
      <Box
        styles={{
          display: 'flex',
          justifyContent: 'spaceBetween',
          alignItems: 'end',
        }}
      >
        <Box styles={{ flexGrow: 1, marginRight: 4 }}>
          <Link href="/">
            <VisuallyHidden>{siteSettings.siteName}</VisuallyHidden>
            <Box styles={{ display: 'flex', position: 'relative' }}>
              <SVG
                x={77}
                y={93}
                svg={AssetLogoIconSVG}
                className={logoIconStyles}
              />
              <SVG
                x={586.27}
                y={30}
                svg={AssetLogoTextSVG}
                className={logoTextStyles}
              />
            </Box>
          </Link>
        </Box>
        <Box
          styles={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end',
          }}
        >
          <Box
            styles={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: 4,
              marginRight: -1,
              opacity: isScrollingDown ? 0 : 100,
              pointerEvents: isScrollingDown ? 'none' : 'auto',
              transitionDuration: 'normal',
              transitionTimingFunction: 'easeInOut',
              transitionProperty: 'opacity',
            }}
          >
            <SecondaryNavList styles={{ marginRight: 5 }}>
              {navigation.secondary?.map?.(
                (item) =>
                  item?.primary?.link?.url && (
                    <SecondaryNavList.Item
                      key={item?.primary?.link?.url}
                      href={item?.primary?.link?.url}
                    >
                      {item?.primary?.name}
                    </SecondaryNavList.Item>
                  ),
              )}
              <SecondaryNavList.Item>
                <Box
                  component="button"
                  onClick={() => openModal('LOST_CARD')}
                  styles={{ fontWeight: 'inherit' }}
                >
                  Report Lost Card
                </Box>
              </SecondaryNavList.Item>
              <SecondaryNavList.Item>
                <Box
                  component="button"
                  onClick={() => openModal('ROUTING_NUMBER')}
                  styles={{ fontWeight: 'inherit' }}
                >
                  Routing Number
                </Box>
              </SecondaryNavList.Item>
            </SecondaryNavList>
            <SearchForm />
          </Box>
          <PrimaryNavList>
            {navigation.primary?.map?.(
              (item) =>
                item?.primary?.link?.url && (
                  <PrimaryNavList.Item
                    key={item.primary.link.url}
                    href={item.primary.link.url}
                    name={item.primary.name}
                  >
                    {item.items?.map(
                      (child) =>
                        child?.link?.url && (
                          <PrimaryNavList.ChildItem
                            key={child.link.url}
                            href={child.link.url}
                            description={child.description?.text}
                          >
                            {child.name}
                          </PrimaryNavList.ChildItem>
                        ),
                    )}
                  </PrimaryNavList.Item>
                ),
            )}
            {withSignInLink && siteSettings.ebankingUrl && (
              <PrimaryNavList.Item
                href={siteSettings.ebankingUrl}
                name="Sign In"
              />
            )}
          </PrimaryNavList>
        </Box>
      </Box>
    </BoundedBox>
  )
}
