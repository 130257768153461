import * as React from 'react'
import { graphql } from 'gatsby'
import { useStyles } from 'react-treat'
import GatsbyImage from 'gatsby-image/withIEPolyfill'
import { FluidObject } from 'gatsby-image'
import { Box } from '@walltowall/calico'
import { getRichText } from '@walltowall/helpers'
import clsx from 'clsx'

import { PageLayoutHeroFragment } from '../graphqlTypes'
import { MapDataToPropsArgs, MapDataToContextArgs } from '../types'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { HTMLContent } from '../components/HTMLContent'
import { ButtonLink } from '../components/ButtonLink'
import { GatsbyImageContainer } from '../components/GatsbyImageContainer'

import * as styleRefs from './PageLayoutHero.treat'

export type PageLayoutHeroProps = Partial<ReturnType<typeof mapDataToProps>> &
  PageTemplateEnhancerProps

export const PageLayoutHero = ({
  imageFluid,
  imageAlt,
  textHTML,
  buttonText = 'Learn more',
  buttonHref,
  buttonTarget,
  nextSharesBg,
  id,
}: PageLayoutHeroProps) => {
  const styles = useStyles(styleRefs)

  return (
    <BoundedBox
      id={id}
      component="section"
      nextSharesBg={nextSharesBg}
      className={clsx(!imageFluid && styles.diamondBackground)}
      styles={{
        backgroundColor: 'beige40',
        color: 'white',
        marginRight: 'auto',
        marginLeft: 'auto',
        maxWidth: 'xlarge',
        position: 'relative',
      }}
    >
      {imageFluid && (
        <GatsbyImageContainer
          variant="cover"
          styles={{ pointerEvents: 'none' }}
        >
          <GatsbyImage fluid={imageFluid} alt={imageAlt} />
        </GatsbyImageContainer>
      )}
      <Box
        className={styles.minHeight}
        styles={{
          display: 'flex',
          alignItems: ['start', 'center'],
          flexDirection: 'column',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        {textHTML && (
          <HTMLContent
            html={textHTML}
            styles={{
              textAlign: ['left', 'center'],
              width: 'full',
              maxWidth: 'small',
            }}
            componentOverrides={{
              h2: (Comp) => (props) => (
                <Comp
                  level={2}
                  variant="sansA"
                  {...props}
                  styles={{
                    marginTop: 0,
                    marginBottom: ['6', '8'],
                  }}
                />
              ),
              h3: (Comp) => (props) => (
                <Comp
                  level={3}
                  variant="sansCapsA"
                  {...props}
                  styles={{
                    marginTop: 0,
                    marginBottom: ['6', '8'],
                  }}
                />
              ),
              p: (Comp) => (props) => (
                <Comp
                  variant="sans-19-22"
                  {...props}
                  styles={{ marginBottom: ['3', '5'] }}
                />
              ),
            }}
          />
        )}
        {buttonHref && (
          <Box styles={{ marginTop: [6, 8] }}>
            <ButtonLink variant="red" href={buttonHref} target={buttonTarget}>
              {buttonText}
            </ButtonLink>
          </Box>
        )}
      </Box>
    </BoundedBox>
  )
}

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageLayoutHeroFragment>) => ({
  imageFluid: data.primary?.image?.fluid as FluidObject,
  imageAlt: data.primary?.image?.alt,
  textHTML: getRichText(data.primary?.body),
  buttonText: data.primary?.button_text,
  buttonHref: data.primary?.button_link?.url,
  buttonTarget: data.primary?.button_link?.target,
})

export const mapDataToContext = ({
  data,
}: MapDataToContextArgs<PageLayoutHeroFragment>) => ({
  bg: data?.primary?.image?.fluid ? Symbol() : 'beige40',
})

export const query = graphql`
  fragment PageLayoutHero on PrismicPageLayoutHero {
    primary {
      image {
        alt
        fluid(maxWidth: 1000) {
          ...GatsbyPrismicImageFluid
        }
      }
      body {
        text
        html
      }
      button_text
      button_link {
        url
        target
      }
    }
  }
`

export default PageLayoutHero
