import * as React from 'react'
import { useStyles } from 'react-treat'
import { Box } from '@walltowall/calico'
import GatsbyImage from 'gatsby-image/withIEPolyfill'

import { PageLayoutDestinationBarProps } from '../PageLayoutDestinationBar'

import { BoundedBox } from '../../components/BoundedBox'
import { HTMLContent } from '../../components/HTMLContent'
import { ButtonLink } from '../../components/ButtonLink'
import { GatsbyImageContainer } from '../../components/GatsbyImageContainer'

import * as styleRefs from './PhotoPromo.treat'
import clsx from 'clsx'

const variants = {
  brown10: {
    backgroundColor: 'brown20',
    color: 'white',
    buttonVariant: 'white',
    imageMarginTop: [-6, -7, -8],
    className: 'diamondDarkBackground',
  },
  brown10Overlap: {
    backgroundColor: 'brown20',
    color: 'white',
    buttonVariant: 'white',
    imageMarginTop: [-13, -14, -15],
    className: 'diamondDarkBackground',
  },
  beige100: {
    backgroundColor: 'beige100',
    color: 'brown20',
    buttonVariant: 'red',
    imageMarginTop: [-6, -7, -8],
    className: 'fadedDiamondBackground',
  },
  beige100Overlap: {
    backgroundColor: 'beige100',
    color: 'brown20',
    buttonVariant: 'red',
    imageMarginTop: [-13, -14, -15],
    className: 'fadedDiamondBackground',
  },
} as const

type PhotoPromoProps = {
  variant: keyof typeof variants
} & PageLayoutDestinationBarProps

export const PhotoPromo = ({
  bodyHTML,
  buttonText,
  buttonHref,
  buttonTarget,
  imageFluid,
  imageAlt,
  variant: variantName,
  nextSharesBg,
  id,
}: PhotoPromoProps) => {
  const styles = useStyles(styleRefs)

  const variant = variants[variantName]
  const className = variant.className && styles[variant.className]

  return (
    <BoundedBox
      id={id}
      component="section"
      nextSharesBg={nextSharesBg}
      innerMaxWidth="small"
      variant="extraNarrow"
      className={clsx(className)}
      styles={{
        backgroundColor: variant.backgroundColor,
        color: variant.color,
        marginRight: 'auto',
        marginLeft: 'auto',
        maxWidth: 'xlarge',
        position: 'relative',
      }}
    >
      <Box
        styles={{ display: 'flex', alignItems: 'center', position: 'relative' }}
      >
        <Box
          styles={{
            display: ['none', 'block'],
            alignSelf: 'stretch',
            marginRight: [4, 10],
            marginTop: variant.imageMarginTop,
            marginBottom: [-6, -7, -8],
            width: '6/12',
          }}
        >
          <GatsbyImageContainer styles={{ height: 'full' }}>
            {imageFluid && (
              <GatsbyImage
                fluid={imageFluid}
                alt={imageAlt}
                objectFit="contain"
                objectPosition="bottom center"
              />
            )}
          </GatsbyImageContainer>
        </Box>
        <Box
          styles={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            width: ['full', '6/12'],
          }}
        >
          {bodyHTML && (
            <HTMLContent
              html={bodyHTML}
              componentOverrides={{
                h2: (Comp) => (props) => (
                  <Comp
                    level={2}
                    variant="sansCapsD"
                    {...props}
                    styles={{
                      marginTop: 0,
                      marginBottom: [6, 8],
                    }}
                  />
                ),
                h4: (Comp) => (props) => <Comp variant="sansE" {...props} />,
                p: (Comp) => (props) => (
                  <Comp
                    {...props}
                    styles={{
                      marginTop: 0,
                      marginBottom: [6, 8],
                    }}
                  />
                ),
              }}
              styles={{ maxWidth: 'small', width: 'full' }}
            />
          )}
          {buttonHref && (
            <Box styles={{ marginTop: [6, 8] }}>
              <ButtonLink
                variant={variant.buttonVariant}
                href={buttonHref}
                target={buttonTarget}
              >
                {buttonText}
              </ButtonLink>
            </Box>
          )}
        </Box>
      </Box>
    </BoundedBox>
  )
}
