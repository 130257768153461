import { graphql } from 'gatsby'
import * as R from 'rambdax'

// 1. Import your slice
import * as PageLayoutAnchor from './PageLayoutAnchor'
import * as PageLayoutButtonCards from './PageLayoutButtonCards'
import * as PageLayoutButtonCardsWithImage from './PageLayoutButtonCardsWithImage'
import * as PageLayoutCallToAction from './PageLayoutCallToAction'
import * as PageLayoutCardGallery from './PageLayoutCardGallery'
import * as PageLayoutContactForm from './PageLayoutContactForm'
import * as PageLayoutCtaExpandable from './PageLayoutCtaExpandable'
import * as PageLayoutDepositRateSchedule from './PageLayoutDepositRateSchedule'
import * as PageLayoutDestinationBar from './PageLayoutDestinationBar'
import * as PageLayoutDestinationBoxes from './PageLayoutDestinationBoxes'
import * as PageLayoutEntrepreneurs2020 from './PageLayoutEntrepreneurs2020'
import * as PageLayoutFaq from './PageLayoutFaq'
import * as PageLayoutFeaturedProduct from './PageLayoutFeaturedProduct'
import * as PageLayoutFooter from './PageLayoutFooter'
import * as PageLayoutHeader from './PageLayoutHeader'
import * as PageLayoutHero from './PageLayoutHero'
import * as PageLayoutHometownHeroes from './PageLayoutHometownHeroes'
import * as PageLayoutImageGallery from './PageLayoutImageGallery'
import * as PageLayoutLargeMessage from './PageLayoutLargeMessage'
import * as PageLayoutLocationsSearch from './PageLayoutLocationsSearch'
import * as PageLayoutPromotions from './PageLayoutPromotions'
import * as PageLayoutRichText from './PageLayoutRichText'
import * as PageLayoutRichTextExpandable from './PageLayoutRichTextExpandable'
import * as PageLayoutSearchResults from './PageLayoutSearchResults'
import * as PageLayoutSocialWayfinding from './PageLayoutSocialWayfinding'
import * as PageLayoutSplitPanels from './PageLayoutSplitPanels'
import * as PageLayoutVideo from './PageLayoutVideo'

// 2. Add your slice
const slices = {
  PageLayoutAnchor,
  PageLayoutButtonCards,
  PageLayoutButtonCardsWithImage,
  PageLayoutCallToAction,
  PageLayoutCardGallery,
  PageLayoutContactForm,
  PageLayoutCtaExpandable,
  PageLayoutDepositRateSchedule,
  PageLayoutDestinationBar,
  PageLayoutDestinationBoxes,
  PageLayoutEntrepreneurs2020,
  PageLayoutFaq,
  PageLayoutFeaturedProduct,
  PageLayoutFooter,
  PageLayoutHeader,
  PageLayoutHero,
  PageLayoutHometownHeroes,
  PageLayoutImageGallery,
  PageLayoutLargeMessage,
  PageLayoutLocationsSearch,
  PageLayoutPromotions,
  PageLayoutRichText,
  PageLayoutRichTextExpandable,
  PageLayoutSearchResults,
  PageLayoutSocialWayfinding,
  PageLayoutSplitPanels,
  PageLayoutVideo,
}

// 3. Add your slice fragment
export const fragment = graphql`
  fragment SlicesPageLayout on PrismicPageLayoutSlicesType {
    ...PageLayoutAnchor
    ...PageLayoutButtonCards
    ...PageLayoutButtonCardsWithImage
    ...PageLayoutCallToAction
    ...PageLayoutCardGallery
    ...PageLayoutContactForm
    ...PageLayoutCtaExpandable
    ...PageLayoutDepositRateSchedule
    ...PageLayoutDestinationBar
    ...PageLayoutDestinationBoxes
    ...PageLayoutEntrepreneurs2020
    ...PageLayoutFaq
    ...PageLayoutFeaturedProduct
    ...PageLayoutHero
    ...PageLayoutHometownHeroes
    ...PageLayoutImageGallery
    ...PageLayoutLocationsSearch
    ...PageLayoutPromotions
    ...PageLayoutRichText
    ...PageLayoutRichTextExpandable
    ...PageLayoutSplitPanels
    ...PageLayoutVideo

    # The following slices do not have fragments:
    # ...PageLayoutFooter
    # ...PageLayoutHeader
    # ...PageLayoutLargeMessage
    # ...PageLayoutSearchResults
    # ...PageLayoutSocialWayfinding
  }
`

export const slicesMap = R.map((module) => {
  const component: typeof module.default & {
    mapDataToProps?: any
    mapDataToContext?: any
  } = module.default
  if ('mapDataToProps' in module)
    component.mapDataToProps = module.mapDataToProps
  if ('mapDataToContext' in module)
    component.mapDataToContext = module.mapDataToContext
  return component
}, slices)
