import * as React from 'react'
import { useStyles } from 'react-treat'
import { navigate } from 'gatsby'
import {
  Box,
  BoxProps,
  useBoxStyles,
  usePseudoBoxStyles,
} from '@walltowall/calico'
import { Formik, Form as FormikForm, Field as FormikField } from 'formik'
import VisuallyHidden from '@reach/visually-hidden'
import querystring from 'querystring'
import ConditionalWrap from 'conditional-wrap'
import clsx from 'clsx'

import { useSiteSettings } from '../../hooks/useSiteSettings'
import { useNavigation } from '../../hooks/useNavigation'

import { BoundedBox } from '../../components/BoundedBox'
import { ButtonLink } from '../../components/ButtonLink'
import { ExpandCircle } from '../../components/ExpandCircle'
import { HamburgerIcon } from '../../components/HamburgerIcon'
import { Heading } from '../../components/Heading'
import { Icon } from '../../components/Icon'
import { Link, LinkProps } from '../../components/Link'
import { SVG } from '../../components/SVG'
import { Text } from '../../components/Text'

import { ReactComponent as AssetLogoTextSVG } from '../../assets/logo-text.svg'

import * as styleRefs from './Mobile.treat'
import { useModal } from '../../hooks/useModal'

type PrimaryNavListProps = {
  children?: React.ReactNode
}

const PrimaryNavList = ({ children }: PrimaryNavListProps) => (
  <Box
    component="ul"
    styles={{ alignSelf: 'stretch', marginTop: -4, marginBottom: 4 }}
  >
    {children}
  </Box>
)

type PrimaryNavListItemProps = {
  href: LinkProps['href']
  name?: string
  children?: React.ReactNode
  defaultIsOpen?: boolean
}

PrimaryNavList.Item = ({
  href,
  name,
  children,
  defaultIsOpen = false,
}: PrimaryNavListItemProps) => {
  const styles = useStyles(styleRefs)

  const [isOpen, toggleIsOpen] = React.useReducer(
    (state) => !state,
    defaultIsOpen,
  )

  const hasChildren = React.Children.count(children) > 0

  const linkClassName = clsx(
    useBoxStyles({
      transitionDuration: 'normal',
      transitionProperty: 'color',
      transitionTimingFunction: 'easeOut',
    }),
    usePseudoBoxStyles({ color: 'maroon10' }, 'focus'),
    usePseudoBoxStyles({ color: 'maroon10' }, 'hover'),
  )

  return (
    <Box component="li">
      <Box styles={{ position: 'relative', paddingTop: 4, paddingBottom: 4 }}>
        <Heading
          level={6}
          component="span"
          variant="sansC"
          styles={{
            display: 'block',
            color: 'fuschia30',
            textAlign: 'center',
            fontWeight: 'light',
          }}
        >
          <Link href={href} className={linkClassName}>
            {name}
          </Link>
        </Heading>
        {hasChildren && (
          <Box
            component="button"
            onClick={toggleIsOpen}
            className={ExpandCircle.focusCaptureClassName}
            styles={{
              position: 'absolute',
              right: 0,
              top: 0,
              bottom: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              paddingRight: 4,
            }}
          >
            <ExpandCircle isExpanded={isOpen} />
            <VisuallyHidden>
              {isOpen
                ? `Close ${name} child links`
                : `Open ${name} child links`}
            </VisuallyHidden>
          </Box>
        )}
      </Box>
      {hasChildren && (
        <Box
          component="ul"
          className={styles.childrenTransitionProperties}
          styles={{
            backgroundColor: 'white',
            maxHeight: isOpen ? '30rem' : 0,
            paddingTop: isOpen ? 4 : undefined,
            paddingBottom: isOpen ? 4 : undefined,
            opacity: isOpen ? 100 : 0,
            overflow: 'hidden',
            transitionDuration: 'slow',
            transitionTimingFunction: 'easeOut',
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  )
}

type PrimaryListChildItemProps = {
  href: LinkProps['href']
  children?: string
}

PrimaryNavList.ChildItem = ({ href, children }: PrimaryListChildItemProps) => {
  const focusStyles = { color: 'fuschia30' } as const
  const focusClassNames = clsx(
    useBoxStyles({
      transitionProperty: 'color',
      transitionDuration: 'normal',
      transitionTimingFunction: 'easeOut',
    }),
    usePseudoBoxStyles(focusStyles, 'hover'),
    usePseudoBoxStyles(focusStyles, 'focus'),
  )

  return (
    <Box component="li" styles={{ paddingTop: 4, paddingBottom: 4 }}>
      <Text
        variant="sans-19-22"
        styles={{ textAlign: 'center', color: 'brown20' }}
      >
        <Link href={href} className={focusClassNames}>
          {children}
        </Link>
      </Text>
    </Box>
  )
}

type SecondaryNavListProps = {
  children?: React.ReactNode
}

const SecondaryNavList = ({ children }: SecondaryNavListProps) => (
  <Box
    component="ul"
    styles={{ alignSelf: 'stretch', marginTop: -3.5, marginBottom: -3.5 }}
  >
    {children}
  </Box>
)

type SecondaryNavItemProps = {
  href?: LinkProps['href']
  children?: React.ReactNode
}

SecondaryNavList.Item = ({ href, children }: SecondaryNavItemProps) => {
  const classNames = clsx(
    usePseudoBoxStyles({ color: 'fuschia30' }, 'focus'),
    usePseudoBoxStyles({ color: 'fuschia30' }, 'hover'),
  )

  return (
    <Box component="li" styles={{ paddingTop: 3.5, paddingBottom: 3.5 }}>
      <Text
        variant="sans-18-20"
        styles={{ textAlign: 'center', color: 'beige40' }}
      >
        <ConditionalWrap
          condition={Boolean(href)}
          wrap={(children) => (
            <Link href={href!} className={classNames}>
              {children}
            </Link>
          )}
        >
          <>{children}</>
        </ConditionalWrap>
      </Text>
    </Box>
  )
}

type MobileProps = {
  isOpen?: boolean
  toggleIsOpen: () => void
} & BoxProps

// Note: open/closed state is managed in ../PageLayoutHeader.tsx
export const Mobile = ({
  isOpen = false,
  toggleIsOpen,
  ...props
}: MobileProps) => {
  const styles = useStyles(styleRefs)

  const siteSettings = useSiteSettings()
  const navigation = useNavigation()
  const { openModal } = useModal()

  const onSearchSubmit = (values: { query: string }) => {
    const path = values.query
      ? `/search/?${querystring.encode({ query: values.query })}`
      : '/search/'
    navigate(path)
  }

  return (
    <Box {...props}>
      <Box
        onClick={toggleIsOpen}
        styles={{
          backgroundColor: 'brown20',
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1,
          opacity: isOpen ? 75 : 0,
          pointerEvents: isOpen ? 'auto' : 'none',
          transitionProperty: 'opacity',
          transitionDuration: 'normal',
          transitionTimingFunction: 'easeOut',
        }}
      />
      <BoundedBox
        innerMaxWidth="large"
        className={clsx(styles.gradientBackground, styles.shadow)}
        styles={{
          color: 'white',
          backgroundPositionX: 'left',
          backgroundPositionY: 'bottom',
          backgroundSize: 'cover',
          paddingTop: 5,
          paddingBottom: 5,
          paddingLeft: [4, 5],
          paddingRight: [4, 5],
          position: 'relative',
          zIndex: 4,
        }}
      >
        <Box
          styles={{
            display: 'flex',
            justifyContent: 'spaceBetween',
            alignItems: 'center',
          }}
        >
          <Box
            styles={{
              flexGrow: 1,
              width: 'full',
              maxWidth: '16rem',
              marginRight: 4,
            }}
          >
            <Link href="/">
              <VisuallyHidden>{siteSettings.siteName}</VisuallyHidden>
              <SVG x={586.27} y={30} svg={AssetLogoTextSVG} />
            </Link>
          </Box>
          <Box styles={{ flexShrink: 0 }}>
            <Box
              component="button"
              onClick={toggleIsOpen}
              styles={{ display: 'block' }}
            >
              <HamburgerIcon isActive={isOpen} styles={{ color: 'white' }} />
            </Box>
          </Box>
        </Box>
      </BoundedBox>
      <Box
        className={clsx(styles.shadow, !isOpen && styles.translateUp)}
        styles={{
          backgroundColor: 'beige100',
          paddingTop: 8,
          paddingBottom: 12,
          color: 'brown20',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'absolute',
          left: 0,
          right: 0,
          // Help
          zIndex: 3,
          transitionProperty: 'transform',
          transitionDuration: 'normal',
          transitionTimingFunction: 'easeOut',
        }}
      >
        <Box
          styles={{
            paddingLeft: 6,
            paddingRight: 6,
            width: 'full',
            maxWidth: '30rem',
            marginBottom: 6,
          }}
        >
          <Formik initialValues={{ query: '' }} onSubmit={onSearchSubmit}>
            <FormikForm>
              <Box styles={{ position: 'relative' }}>
                <VisuallyHidden>
                  <label htmlFor="query">Search</label>
                </VisuallyHidden>
                <FormikField
                  name="query"
                  as={(props: object) => (
                    <Box
                      component="input"
                      type="search"
                      id="query"
                      className={styles.placeholderColor}
                      placeholder="Search&hellip;"
                      styles={{
                        backgroundColor: 'white',
                        color: 'brown20',
                        paddingTop: 3,
                        paddingBottom: 3,
                        paddingLeft: 4,
                        paddingRight: 12,
                        borderRadius: '10em',
                        lineHeight: 'solid',
                        fontFamily: 'sans',
                        borderColor: 'beige80',
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        width: 'full',
                      }}
                      {...props}
                    />
                  )}
                />
                <Box
                  component="button"
                  styles={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingRight: 4,
                    paddingLeft: 4,
                  }}
                >
                  <VisuallyHidden>Submit search</VisuallyHidden>
                  <Icon
                    name="search"
                    className={styles.iconTranslate}
                    styles={{ color: 'beige40', width: '1rem' }}
                  />
                </Box>
              </Box>
            </FormikForm>
          </Formik>
        </Box>
        <Box styles={{ marginBottom: 6 }}>
          <ButtonLink variant="red" href={siteSettings.ebankingUrl!}>
            Sign In
          </ButtonLink>
        </Box>
        <PrimaryNavList>
          {navigation.primary?.map?.(
            (item) =>
              item?.primary?.link?.url && (
                <PrimaryNavList.Item
                  key={item.primary.link.url}
                  href={item.primary.link.url}
                  name={item.primary.name}
                >
                  {item.items?.map(
                    (child) =>
                      child?.link?.url && (
                        <PrimaryNavList.ChildItem
                          key={child.link.url}
                          href={child.link.url}
                        >
                          {child.name}
                        </PrimaryNavList.ChildItem>
                      ),
                  )}
                </PrimaryNavList.Item>
              ),
          )}
        </PrimaryNavList>
        <SecondaryNavList>
          {navigation.secondary?.map?.((item) => (
            <SecondaryNavList.Item
              key={item?.primary?.link?.url}
              href={item?.primary?.link?.url}
            >
              {item?.primary?.name}
            </SecondaryNavList.Item>
          ))}
          <SecondaryNavList.Item>
            <Box component="button" onClick={() => openModal('LOST_CARD')}>
              Report Lost Card
            </Box>
          </SecondaryNavList.Item>
          <SecondaryNavList.Item>
            <Box component="button" onClick={() => openModal('ROUTING_NUMBER')}>
              Routing Number
            </Box>
          </SecondaryNavList.Item>
        </SecondaryNavList>
      </Box>
    </Box>
  )
}
