import * as React from 'react'
import { useStyles } from 'react-treat'
import { Box } from '@walltowall/calico'

import { useSiteSettings } from '../../hooks/useSiteSettings'

import { BoundedBox } from '../../components/BoundedBox'
import { Heading } from '../../components/Heading'
import { ButtonLink } from '../../components/ButtonLink'

import * as styleRefs from './SignInBar.treat'

type SignInBarProps = {
  showMobileSignInButton?: boolean
}

export const SignInBar = ({
  showMobileSignInButton = true,
}: SignInBarProps) => {
  const styles = useStyles(styleRefs)
  const siteSettings = useSiteSettings()

  return (
    <BoundedBox
      innerMaxWidth="large"
      className={styles.shadow}
      styles={{
        backgroundColor: 'beige100',
        position: 'relative',
        zIndex: 2,
        paddingTop: [8, 6],
        paddingBottom: [10, 6],
        paddingLeft: [4, 5, 16],
        paddingRight: [4, 5, 16],
      }}
    >
      <Box
        className={styles.diamondBackground}
        styles={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          opacity: 15,
          pointerEvents: 'none',
        }}
      />
      {siteSettings.ebankingUrl && (
        <Box
          className={styles.translateY50}
          styles={{
            display: ['block', 'none'],
            position: 'absolute',
            bottom: 0,
            transitionProperty: 'opacity',
            transitionDuration: 'normal',
            transitionTimingFunction: 'easeOut',
            pointerEvents: showMobileSignInButton ? 'auto' : 'none',
            opacity: showMobileSignInButton ? 100 : 0,
          }}
        >
          <ButtonLink
            variant="white"
            href={siteSettings.ebankingUrl}
            className={styles.shadow}
          >
            Sign In
          </ButtonLink>
        </Box>
      )}
      <Box
        styles={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'spaceBetween',
        }}
      >
        <Heading
          level={2}
          variant="sansD"
          styles={{ color: 'beige40', position: 'relative' }}
        >
          Welcome
        </Heading>
        {siteSettings.ebankingSignInEndpoint && (
          <Box
            component="iframe"
            src={siteSettings.ebankingSignInEndpoint}
            styles={{
              borderWidth: 'none',
              display: ['none', 'block'],
              position: 'relative',
              height: '3.75rem',
              width: '22rem',
              marginBottom: -3,
              marginRight: -1,
            }}
          />
        )}
      </Box>
    </BoundedBox>
  )
}
