import * as React from 'react'
import { graphql } from 'gatsby'
import { useStyles } from 'react-treat'
import { Box, BoxProps } from '@walltowall/calico'
import { getRichText } from '@walltowall/helpers'

import { PageLayoutDepositRateScheduleFragment } from '../graphqlTypes'
import { PickPartial, MapDataToPropsArgs } from '../types'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { StyledHTMLContent } from '../components/StyledHTMLContent'
import { Heading } from '../components/Heading'
import { Text } from '../components/Text'
import { Divider } from '../components/Divider'

import * as styleRefs from './PageLayoutDepositRateSchedule.treat'
import { HTMLContent } from '../components/HTMLContent'

const MobileTableHeading = ({ children, ...props }: BoxProps) => (
  <Box
    {...props}
    styles={{
      paddingBottom: 3,
      paddingRight: 4,
      width: '12rem',
      flexGrow: 0,
      flexShrink: 0,
      scrollSnapAlign: 'start',
      ...props.styles,
    }}
  >
    <Heading
      level={6}
      component="span"
      variant="sansCapsC"
      styles={{ display: 'block', color: 'beige40' }}
    >
      {children}
    </Heading>
  </Box>
)

const MobileTableCell = ({ childrenHTML }: { childrenHTML?: string }) => (
  <Box
    styles={{
      paddingRight: 4,
      width: '12rem',
      flexGrow: 0,
      flexShrink: 0,
      color: 'brown20',
    }}
  >
    <HTMLContent
      html={childrenHTML}
      componentOverrides={{
        p: (Comp) => (props) => <Comp variant="sans-19-22" {...props} />,
      }}
    />
  </Box>
)

type MobileProps = {
  items: PageLayoutDepositRateScheduleProps['items']
} & BoxProps

const Mobile = ({ items, ...props }: MobileProps) => {
  const styles = useStyles(styleRefs)

  return (
    <Box {...props} styles={{ position: 'relative', ...props.styles }}>
      <Text
        component="p"
        variant="sans-12"
        styles={{ fontWeight: 'semibold', color: 'fuschia30', marginBottom: 4 }}
      >
        Swipe to compare features
      </Text>
      <Box
        styles={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
          scrollSnapType: 'xMandatory',
          marginBottom: -6,
          position: 'relative',
        }}
      >
        <Box styles={{ display: 'flex' }}>
          <MobileTableHeading>Minimum Deposit</MobileTableHeading>
          <MobileTableHeading>
            Minimum Daily Balance to Earn Interest
          </MobileTableHeading>
          <MobileTableHeading>Interest Rate</MobileTableHeading>
          <MobileTableHeading>
            Annual Percentage Yield (APY)*
          </MobileTableHeading>
        </Box>
        {items?.map?.((item) => (
          <>
            <Divider
              color="beige80"
              styles={{
                height: '2px',
                marginBottom: 4,
                position: 'sticky',
                left: 0,
                zIndex: 1,
              }}
            />
            <Heading
              level={6}
              component="span"
              variant="serifCapsA"
              styles={{
                display: 'block',
                color: 'fuschia30',
                marginBottom: 4,
                position: 'sticky',
                left: 0,
                zIndex: 1,
              }}
            >
              {item.accountType}
            </Heading>
            <Box key={item.accountType} styles={{ marginBottom: 6 }}>
              <Box styles={{ display: 'flex' }}>
                <MobileTableCell childrenHTML={item.minDepositHTML} />
                <MobileTableCell childrenHTML={item.minDailyBalanceHTML} />
                <MobileTableCell childrenHTML={item.interestRateHTML} />
                <MobileTableCell
                  childrenHTML={item.annualPercentageYieldHTML}
                />
                <MobileTableCell />
              </Box>
            </Box>
          </>
        ))}
      </Box>
      <Box
        className={styles.mobileGradientOverlay}
        styles={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          width: '4/12',
          pointerEvents: 'none',
        }}
      />
    </Box>
  )
}

const DesktopTableHeading = ({ children, ...props }: BoxProps) => (
  <Box
    component="th"
    // @ts-expect-error - align is not a valid BoxProps prop
    align="center"
    {...props}
    styles={{
      backgroundColor: 'beige90',
      padding: [4, 4, 5],
      ...props.styles,
    }}
  >
    <Heading
      component="span"
      level={6}
      variant="sansCapsC"
      styles={{ display: 'block', color: 'beige40' }}
    >
      {children}
    </Heading>
  </Box>
)

type DesktopTableCellProps = {
  childrenHTML?: string
} & BoxProps

const DesktopTableCell = ({
  children,
  childrenHTML,
  ...props
}: DesktopTableCellProps) => (
  <Box
    component="td"
    // @ts-expect-error - align is not a valid BoxProps prop
    align="center"
    {...props}
    styles={{
      backgroundColor: 'beige100',
      padding: [4, 4, 5],
      paddingBottom: [6, 6, 7],
      ...props.styles,
    }}
  >
    {children ?? (
      <HTMLContent
        html={childrenHTML}
        componentOverrides={{
          p: (Comp) => (props) => <Comp variant="sans-19-22" {...props} />,
        }}
      />
    )}
  </Box>
)

type DesktopProps = {
  items: PageLayoutDepositRateScheduleProps['items']
} & BoxProps

const Desktop = ({ items, ...props }: DesktopProps) => (
  <Box
    component="table"
    {...props}
    styles={{
      borderSpacing: '2px',
      borderCollapse: 'separate',
      ...props.styles,
    }}
  >
    <Box component="thead">
      <DesktopTableHeading
        width="25%"
        // @ts-expect-error - align is not a valid BoxProps prop
        align="left"
      >
        Account Type
      </DesktopTableHeading>
      <DesktopTableHeading width="12.5%">Minimum Deposit</DesktopTableHeading>
      <DesktopTableHeading width="25%">
        Minimum Daily Balance to Earn Interest
      </DesktopTableHeading>
      <DesktopTableHeading width="12.5%">Interest Rate</DesktopTableHeading>
      <DesktopTableHeading width="25%">
        Annual Percentage Yield (APY)*
      </DesktopTableHeading>
    </Box>
    <Box
      component="tbody"
      // @ts-expect-error - valign is not a valid BoxProps prop
      valign="top"
      styles={{ color: 'brown20' }}
    >
      {items?.map?.((item) => (
        <Box key={item.accountType} component="tr">
          <DesktopTableCell
            // @ts-expect-error - align is not a valid BoxProps prop
            align="left"
          >
            <Heading
              level={6}
              component="span"
              variant="serifCapsA"
              styles={{ display: 'block', color: 'fuschia30' }}
            >
              {item.accountType}
            </Heading>
          </DesktopTableCell>
          <DesktopTableCell childrenHTML={item.minDepositHTML} />
          <DesktopTableCell childrenHTML={item.minDailyBalanceHTML} />
          <DesktopTableCell childrenHTML={item.interestRateHTML} />
          <DesktopTableCell childrenHTML={item.annualPercentageYieldHTML} />
        </Box>
      ))}
    </Box>
  </Box>
)

export type PageLayoutDepositRateScheduleProps = PickPartial<
  ReturnType<typeof mapDataToProps>,
  'introHTML' | 'footnoteHTML'
> &
  PageTemplateEnhancerProps

export const PageLayoutDepositRateSchedule = ({
  introHTML,
  footnoteHTML,
  items,
  nextSharesBg,
  id,
}: PageLayoutDepositRateScheduleProps) => (
  <BoundedBox
    id={id}
    component="section"
    innerMaxWidth="medium"
    nextSharesBg={nextSharesBg}
    styles={{
      backgroundColor: 'white',
      maxWidth: 'xlarge',
      marginRight: 'auto',
      marginLeft: 'auto',
      overflow: 'hidden',
    }}
  >
    {introHTML && (
      <StyledHTMLContent
        html={introHTML}
        styles={{ marginBottom: [6, 7, 8] }}
      />
    )}
    <Mobile items={items} styles={{ display: ['block', 'none'] }} />
    <Desktop items={items} styles={{ display: ['none', 'table'] }} />
    {footnoteHTML && (
      <StyledHTMLContent
        html={footnoteHTML}
        styles={{ marginTop: [6, 7, 8] }}
      />
    )}
  </BoundedBox>
)

export const mapDataToProps = ({
  data,
}: MapDataToPropsArgs<PageLayoutDepositRateScheduleFragment>) => ({
  introHTML: getRichText(data.primary?.intro),
  footnoteHTML: getRichText(data.primary?.footnote),
  items: data.items?.map?.((item) => ({
    accountType: item?.account_type?.text,
    minDepositHTML: getRichText(item?.min_deposit),
    minDailyBalanceHTML: getRichText(item?.min_daily_balance_to_earn_interest),
    interestRateHTML: getRichText(item?.interest_rate),
    annualPercentageYieldHTML: getRichText(item?.annual_percentage_yield),
  })),
})

export const mapDataToContext = () => ({
  bg: 'white',
})

export const query = graphql`
  fragment PageLayoutDepositRateSchedule on PrismicPageLayoutDepositRateSchedule {
    primary {
      intro {
        html
        text
      }
      footnote {
        html
        text
      }
    }
    items {
      account_type {
        text
      }
      min_deposit {
        html
        text
      }
      min_daily_balance_to_earn_interest {
        html
        text
      }
      interest_rate {
        html
        text
      }
      annual_percentage_yield {
        html
        text
      }
    }
  }
`

export default PageLayoutDepositRateSchedule
